import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

function SEO({ description, image: metaImage, title, schema, canonicalUrl }) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              author
              description
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const metaTitle = title || data.site.siteMetadata.title;
        const metaDescription = description || data.site.siteMetadata.description;
        const image = metaImage && metaImage.src ? `${data.site.siteMetadata.siteUrl}${metaImage.src}` : null;
        return (
          <Helmet
            htmlAttributes={{
              lang: "sv",
            }}
            title={metaTitle}
            meta={[
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                name: "twitter:creator",
                content: data.site.siteMetadata.author,
              },
              {
                name: "twitter:title",
                content: title,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
            ].concat(
              metaImage
                ? [
                    {
                      property: "og:image",
                      content: image,
                    },
                    {
                      property: "og:image:width",
                      content: metaImage.width,
                    },
                    {
                      property: "og:image:height",
                      content: metaImage.height,
                    },
                    {
                      name: "twitter:card",
                      content: "summary_large_image",
                    },
                  ]
                : [
                    {
                      name: "twitter:card",
                      content: "summary",
                    },
                  ]
            )}
          >
            {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          </Helmet>
        );
      }}
    />
  );
}
export default SEO;
